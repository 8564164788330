@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
  composes: bryant font-default from global;

  position: relative;

  margin-top: calc(var(--line-height-small) / 4);
  padding-bottom: calc(var(--line-height-small) / 4);

  &::after {
    display: none;

    content: "";

    position: absolute;
    z-index: 1;

    left: 0;
    top: 100%;

    width: 100%;
    height: calc(var(--line-height-small) / 4);
  }
}

.root:last-child {
  margin-bottom: 0;
}

// .line_top {
//   display: none;
// }

.line_bottom {
  display: none;
}

.root:first-child .line_top {
  display: block;
}

:last-child > .root:last-child .line_bottom {
  display: block;
}

.link {
  display: flex;
  flex-wrap: wrap;
}

.past {
  opacity: var(--calendar-component--text-opacity, 0.5);
}

// @for $i from 1 through chapter(max-num) {
//   .chapter-#{$i} {
//     composes: chapter-#{$i}-c chapter-#{$i}-bc from global;
//   }
// }
//
// .chapter-3-5 {
//   composes: chapter-3-5-c chapter-3-5-bc from global;
// }

.link {
  color: inherit;
  text-decoration: none;
}

.date {
  composes: font-small hide-current-year from global;

  margin-right: calc(var(--line-height-small) / 2);

  flex: 0 0 auto;
}

.date_query {
  composes: font-small from global;

  flex: 0 0 auto;
}

.tags {
  composes: font-small from global;

  margin: {
    left: calc(var(--line-height-small) / 2);
    right: calc(var(--line-height-small) / 2);
  }

  flex: 0 0 auto;
}

.tickets,
.ticket_details {
  composes: font-small from global;

  margin-left: calc(var(--line-height-small) / 2);

  flex: 1 0 auto;

  text-transform: uppercase;
  text-align: right;
  text-decoration: none;

  color: inherit;
}

.past .tickets {
  display: none;
}

.title {
  composes: bryant from global;

  margin: 0;

  font-weight: inherit;
  flex: 1 0 100%;
}
