@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
  composes: cell from "./shock_forest_log_module.module.scss";

  composes: font-small hide-current-year from global;

  position: relative;
}

.document {
  margin-bottom: calc(var(--line-height-small) / 4);

  a {
    @include link(underline, default);
    color: currentColor;
    word-wrap: break-word;
  }
}

.video {
  margin-bottom: calc(var(--line-height-small) / 4);

  video {
    width: 100%;
    height: auto;
  }
}

.photo {
  margin: {
    top: 0;
    left: 0;
    right: 0;
    bottom: calc(var(--line-height-small) / 4);
  }

  padding: 0;

  @include responsive_image;
}

.caption,
.description,
.text,
.caption {
  composes: font-small from global;

  p {
    @include reset-p;
  }

  sup {
    font-size: 0.5em;
    line-height: 0;
  }
}

.link {
  @include link(underline, default);
  color: inherit;
  word-wrap: break-word;
}

.caption,
.text {
  padding: 0;
  margin: {
    top: 0;
    left: 0;
    right: 0;
    bottom: calc(var(--line-height-small) / 4);
  }
}

.tags {
  margin: 0;
  padding: 0;
}

.tag {
  composes: outlined from "../../../shared/utilities/pill.module.css";

  text-transform: uppercase;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}

.author {
  composes: default from "../../../shared/utilities/pill.module.css";

  cursor: default;
}

.text {
}

.week {
  composes: hide-day-name hide-same-month from global;

  padding-left: calc(var(--web-module-padding) / 2);

  position: absolute;
  height: 100%;

  top: 0;
  left: calc(var(--web-module-padding) / 2 * -1);

  pointer-events: none;

  white-space: nowrap;
}

.line_left {
  position: absolute;

  top: 0;
  left: calc(-1 * var(--web-module-padding) / 2);
  bottom: 0;

  width: var(--web-module-padding);
}

.first_in_week {
  height: 100%;
}
