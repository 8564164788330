/* NOTE: do not use any scss here, or it will break webpack */

.root {
  margin-bottom: calc(var(--web-module-padding) / 2);
}

.content {
  composes: bryant background-gradient-to-top-after from global;

  padding-top: calc(var(--web-module-padding) / 2);
  padding-bottom: calc(var(--web-module-padding) / 2);

  color: var(--text-color);
  background-color: var(--background-color);
}

/* Overlap gradient */
.content::after {
  content: "";

  display: block;

  height: calc(var(--web-module-padding) / 2);
  width: 100%;

  position: absolute;
  top: 100%;
  left: 0;

  z-index: var(--z-index-modal--header);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top;

  user-select: none;
  pointer-events: none;
}

.root:global(.modulor_web_module__is_edit_mode) .content::before,
.root:global(.modulor_web_module__is_edit_mode) .content::after,
.root:global(.modulor_web_module__is_edit_mode) .header::before {
  display: none;
}

.pill_buttons {
  margin-top: calc(var(--line-height-small) / 2);

  z-index: var(--z-index-modal--pill-buttons);
  position: relative;
}
