@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
}

.content {
  composes: bryant font-small from global;

  p {
    @include reset-p;
  }
}

.signed_in {
}

.registration_form {
  form {
    margin: 0;
    padding: 0;
  }

  :global(.input) {
    padding: 0.2em;
    margin-bottom: 0.5em;

    :global(.error) {
      color: var(--color-red);
      display: block;
    }
  }

  :global(div.input.boolean) {
    :global(label.boolean) {
      display: block;
      text-indent: -1em;
      padding-left: 1em;
    }

    :global(input.boolean) {
      display: inline-block;
      margin: 0;
      padding: 0;
      width: 1.5em;
    }
  }

  :global(div.input.email),
  :global(div.input.string) {
    border: 0.2rem solid;

    input[type="email"],
    input[type="text"] {
      @include reset-input;

      @include bryant-compressed;
      @include font-size(small);

      width: 100%;

      &:focus {
        outline: 0;
      }
    }
  }

  :global(div.input.press_registration_first_name),
  :global(div.input.press_registration_last_name) {
    display: inline-block;
    width: calc(#{fluid-col(1, 2)} - 0.25em);
  }

  :global(div.input.press_registration_first_name) {
    margin-right: 0.25em;
  }

  :global(div.input.press_registration_last_name) {
    margin-left: 0.25em;
  }
}

.submit_button {
  composes: default from "../../../shared/utilities/pill.module.css";

  input {
    all: unset;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}

.arrow {
  display: inline-block;

  width: 0.7em;
  height: 0.7em;

  margin-left: 0.2em;
  margin-right: -0.1em;

  line,
  polyline {
    stroke: var(--color-gray-light);
    stroke-width: 0.055em;
  }
}

.alert,
.notice {
  margin-bottom: 1em;
}

.alert {
  color: var(--color-red);
}

.notice {
  color: var(--color-green);
}

.sign_out_link {
  @include button(default, small, var(--color-black), var(--color-gray-light));
}
