@mixin svg-line-bg($dir: horizontal, $color: color(var(--color-text)), $width: 4) {
  background-image: svg-uri(svg-line($dir, $color, $width));

  @if $dir == horizontal {
    background-size: #{"100%" $width / 10 + "rem"};
  }

  @if $dir == vertical {
    background-size: #{$width / 10 + "rem" "100%"};
  }

  @include breakpoints(xs) {
    $multiplier: 0.6;
    background-image: svg-uri(svg-line($dir, $color, $width * $multiplier));
    @if $dir == horizontal {
      background-size: #{"100%" $width * $multiplier / 10 + "rem"};
    }
    @if $dir == vertical {
      background-size: #{$width * $multiplier / 10 + "rem" "100%"};
    }
  }

  @include breakpoints(s m l) {
    $multiplier: 0.75;
    background-image: svg-uri(svg-line($dir, $color, $width * $multiplier));
    @if $dir == horizontal {
      background-size: #{"100%" $width * $multiplier / 10 + "rem"};
    }
    @if $dir == vertical {
      background-size: #{$width * $multiplier / 10 + "rem" "100%"};
    }
  }

  background-repeat: no-repeat;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-clip: border-box;
}
