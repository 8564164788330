.root {
  composes: root from "../modal_pages/modal_page.module.scss";
  composes: bg-gray-light from global;

  color: var(--text-color);
}

.past {
  opacity: initial;
  composes: bg-gray-dark from global;
}

.pill_buttons {
  composes: pill_buttons from "../modal_pages/modal_page.module.scss";
}
