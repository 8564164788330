@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
  z-index: 0;
}

.root [data-chapter-number="5"] {
  pointer-events: none;
}

.root [data-chapter-number="5"] a,
.root [data-chapter-number="5"] button {
  pointer-events: all;
}

.content {
  composes: bryant from global;
  --fade-height: calc(var(--line-height-large) * 3);

  position: sticky;
  bottom: calc(-1 * var(--chapter-module-height, 100vh) + (var(--fade-height) * 1));
  transition: bottom .35s ease-in-out;

  &:hover {
    bottom: calc(-1 * var(--chapter-module-height, 100vh) + var(--fade-height));
  }

  background-color: var(--chapter-color, color(white));
  -webkit-mask-image: linear-gradient(
                                      rgba(#000, 0) calc(0 * var(--fade-height) / 100),
                                      rgba(#000, 0.013) calc(8.1 * var(--fade-height) / 100),
                                      rgba(#000, 0.049) calc(15.5 * var(--fade-height) / 100),
                                      rgba(#000, 0.104) calc(22.5 * var(--fade-height) / 100),
                                      rgba(#000, 0.175) calc(29 * var(--fade-height) / 100),
                                      rgba(#000, 0.259) calc(35.3 * var(--fade-height) / 100),
                                      rgba(#000, 0.352) calc(41.2 * var(--fade-height) / 100),
                                      rgba(#000, 0.45) calc(47.1 * var(--fade-height) / 100),
                                      rgba(#000, 0.55) calc(52.9 * var(--fade-height) / 100),
                                      rgba(#000, 0.648) calc(58.8 * var(--fade-height) / 100),
                                      rgba(#000, 0.741) calc(64.7 * var(--fade-height) / 100),
                                      rgba(#000, 0.825) calc(71 * var(--fade-height) / 100),
                                      rgba(#000, 0.896) calc(77.5 * var(--fade-height) / 100),
                                      rgba(#000, 0.951) calc(84.5 * var(--fade-height) / 100),
                                      rgba(#000, 0.987) calc(91.9 * var(--fade-height) / 100),
                                      rgba(#000, 1) calc(100% - var(--fade-height)),
                                      rgba(#000, 1) 100%
                                     );

  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.iframe {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  border: none;

  z-index: -1;

  pointer-events: all;
}

.container {
  --text-color: var(--color-black);
  --background-color: var(--chapter-color);
  --pill-component--outlined--bg-color--overwrite: var(--background-color);
  --pill-component--default-inverted--bg-color--overwrite: var(--background-color);

  composes: page-container from global;

  color: var(--text-color);

  display: flex;
  flex-direction: column;

  padding-left: var(--web-module-padding);
  padding-right: var(--web-module-padding);
  padding-top: calc(var(--fade-height) + var(--web-module-padding));

  min-height: calc(100vh - var(--web-module-padding) + var(--fade-height));

  background-size: 100% 100%;
  background-origin: content-box;
  background-position: center top;
}

@for $i from 1 through chapter(max-num) {
  .container-#{$i} {
    @if ($i != 5) {
      composes: svg-c-black-4-#{$i} from global;
    }
  }
}

._section {
  margin-bottom: calc(var(--line-height-small) / 2);
}

.line_above {
}

.headings {
  composes: _section;

  margin-bottom: calc(var(--line-height-large) / 2);
}

.guest, .motto, .date_range {
  composes: _section;
  composes: font-large from global;
}

.guest, .motto {
  margin: {
    top: 0;
    bottom: calc(var(--line-height-large) / 2);
  }
}

.motto {
  flex: 1 0 auto;
}

.date_range {
  composes: hide-day-name hide-current-year from global;

  flex: 1 0 auto;
}

.date_range div {
  display: inline;
}

.has_carousel .headings > * {
  margin-right: calc(var(--line-height-large) * 2);

  position: relative;
  z-index: 1;
}

.images {
  composes: _section;

  overflow: visible;

  position: relative;
  z-index: 0;

  :global(.flickity-viewport) {
    overflow: visible;
  }
}

.images > * {
  margin-top: calc(
    -1 * var(--line-height-large) - var(--line-height-small) / 2
  );

  &[data-number-of-cells="1"] {
    margin-top: 0;
  }
}

.buttons {
  composes: font-default from global;
}

.description {
  composes: _section;
}

.heading_link {
  composes: link-underline from global;
}

.videos_container {
}

.videos {
  composes: _section;

  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;

  z-index: -1;

  mix-blend-mode: multiply;

  [class*="retain_aspect_ratio"] {
    height: 100%;
    padding-bottom: 0%;
  }
}

.videos * {
  mix-blend-mode: multiply;
}

.videos_aspect_ratio_wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
