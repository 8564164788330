@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
  composes: bryant font-default from global;

  background-color: var(--color-white);

  max-height: 100vh;
  overflow-y: scroll;
}

.root:after {
  --fade-height: calc(var(--line-height-large) * 1);

  background-color: var(--color-white);

  content: " ";
  display: block;

  position: absolute;
  left: 0;
  width: 100%;
  height: var(--fade-height);

  margin-top: -.1rem;

  -webkit-mask-image: linear-gradient(to top,
                                      rgba(#000, 0) calc(0 * var(--fade-height) / 100),
                                      rgba(#000, 0.013) calc(8.1 * var(--fade-height) / 100),
                                      rgba(#000, 0.049) calc(15.5 * var(--fade-height) / 100),
                                      rgba(#000, 0.104) calc(22.5 * var(--fade-height) / 100),
                                      rgba(#000, 0.175) calc(29 * var(--fade-height) / 100),
                                      rgba(#000, 0.259) calc(35.3 * var(--fade-height) / 100),
                                      rgba(#000, 0.352) calc(41.2 * var(--fade-height) / 100),
                                      rgba(#000, 0.45) calc(47.1 * var(--fade-height) / 100),
                                      rgba(#000, 0.55) calc(52.9 * var(--fade-height) / 100),
                                      rgba(#000, 0.648) calc(58.8 * var(--fade-height) / 100),
                                      rgba(#000, 0.741) calc(64.7 * var(--fade-height) / 100),
                                      rgba(#000, 0.825) calc(71 * var(--fade-height) / 100),
                                      rgba(#000, 0.896) calc(77.5 * var(--fade-height) / 100),
                                      rgba(#000, 0.951) calc(84.5 * var(--fade-height) / 100),
                                      rgba(#000, 0.987) calc(91.9 * var(--fade-height) / 100),
                                      rgba(#000, 1) calc(100% - var(--fade-height)),
                                      rgba(#000, 1) 100%,
                                     );
}

.container {
  composes: page-container from global;

  padding: {
    left: var(--web-module-padding);
    right: var(--web-module-padding);
  }
}

.form {
  composes: bryant font-large from global;

  display: flex;
  align-items: center;
  padding: 0;
}

.search_field {
  flex: 1;

  appearance: none;

  background: transparent;
  border-radius: 0;
  border: 0;
  outline: none;

  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  line-height: inherit;

  margin: 0;
  padding: 0;

  color: inherit;

  display: block;

  width: 100%;
}

.button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  display: inline;

  color: inherit;

  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  line-height: inherit;

  cursor: pointer;

  display: block;
  width: 1em;
  height: 1em;

  @include breakpoints(s xs) {
    display: none;
  }
}

.button svg {
  height: 100%;
  width: auto;
}

.button_to_close_container {
  composes: font-large from global;

  position: fixed;
  top: 0;
  right: 0;
}

.button_to_close {
  composes: bryant font-large icon-button from global;

  padding: 0 !important;
}

.button_to_close_wrapper {
  composes: icon-button from global;

  height: var(--line-height-large);
}

.search_field::placeholder {
  color: var(--color-gray);
}

.search_field::-webkit-search-cancel-button,
.search_field::-webkit-search-decoration {
  appearance: none;
  display: none;
}

.link_to_all_results {
  @include underline-for(default);

  color: inherit;
  text-decoration: none;
}
