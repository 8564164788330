@charset "UTF-8";
.content {
  composes: bryant page-container from global; }

.motto {
  composes: font-large from global;
  padding: 0;
  margin: 0;
  font-style: normal; }
  .motto::before {
    content: "‘"; }
  .motto::after {
    content: "’"; }

.author {
  composes: font-default from global;
  margin-top: 0.25em;
  font-style: normal;
  display: block; }
