._base {
  composes: bryant from global;

  line-height: inherit;
  font-size: inherit;

  border-radius: 9999px;
  text-decoration: none;

  display: inline-block;
  align-items: center;
  vertical-align: middle;

  padding: 0 0.5em;

  border: 1px solid transparent;

  cursor: pointer;

  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.default {
  --pill-component--default--bg-color: var(--pill-component--default--bg-color--overwrite, var(--text-color));

  composes: _base;
  background-color: var(--pill-component--default--bg-color, var(--color-black));
  border-color: var(--pill-component--default--bg-color, var(--color-black));
  color: var(--background-color, var(--color-white));
}

.outlined {
  --pill-component--outlined--bg-color: var(--pill-component--outlined--bg-color--overwrite, var(--background-color));

  composes: _base;
  background-color: var(--pill-component--outlined--bg-color, var(--color-white));
  border-color: var(--text-color, var(--color-black));
  color: var(--text-color, var(--color-black));
}

@media (hover: hover) {
  .outlined:hover {
    background-color: var(--text-color, var(--color-black));
    border-color: var(--text-color, var(--color-black));
    color: var(--background-color, var(--color-white));
  }
}

.default.inverted {
  --pill-component--default-inverted--bg-color: var(--pill-component--default-inverted--bg-color--overwrite, var(--background-color));

  background-color: var(--pill-component--default-inverted--bg-color, var(--color-white));
  border-color: var(--text-color, var(--color-black));
  color: var(--text-color, var(--color-black));
}

.outlined.inverted {
  --pill-component--outlined-inverted--bg-color: var(--pill-component--outlined-inverted--bg-color--overwrite, var(--background-color));

  background-color: var(--text-color, var(--color-black));
  border-color: var(--pill-component--outlined-inverted--bg-color, var(--color-white));
  color: var(--pill-component--outlined-inverted--bg-color, var(--color-white));
}

@media (hover: hover) {
  .outlined.inverted:hover {
    background-color: var(--text-color, var(--color-black));
    border-color: var(--background-color, var(--color-white));
    color: var(--background-color, var(--color-white));
  }
}
