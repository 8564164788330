@mixin svg-circle-bg($color: color(black), $width: 4, $count: 1, $padding: 0) {
  background-image: svg-uri(svg-circle($color, $width, $count, $padding));

  @include breakpoints(xs) {
    $multiplier: 0.6;
    background-image: svg-uri(
      svg-circle($color, $width * $multiplier, $count, $padding)
    );
  }

  @include breakpoints(s m l) {
    $multiplier: 0.75;
    background-image: svg-uri(
      svg-circle($color, $width * $multiplier, $count, $padding)
    );
  }

  background-clip: border-box;
  background-origin: padding-box;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}
