@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
  composes: bryant font-large from global;

  user-select: none;

  --text-color: var(--color-black);
  --border-color: var(--text-color);

  --background-color: var(--color-gray);
}

.toggle_button {
  position: fixed;
  top: 0;
  right: calc(var(--web-module-padding) - 0.15em);
  z-index: var(--z-index-menu-under-modal);

  @include breakpoints(m l xl) {
    right: 0;
    z-index: var(--z-index-menu);
  }
}

:global(.user_signed_in) {
  .toggle_button {
    position: relative;
    height: 0;
  }
}

.menu {
  color: var(--text-color);
  background-color: var(--background-color);


  display: block;

  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);

  position: fixed;
  top: 100%;
  z-index: var(--z-index-menu-under-modal);

  @include breakpoints(m l xl) {
    z-index: var(--z-index-menu);
  }

  overflow: hidden;

  will-change: transform;
  -webkit-backface-visibility: hidden;
  
  transition: transform 0.25s;
}

:global(.modulor_web_module__is_edit_mode) {
  .root {
    position: relative !important;
  }

  .toggle_button {
    position: absolute !important;
    pointer-events: none !important;
  }

  .menu {
    position: static !important;
    top: 0 !important;
    transform: none !important;
    height: auto !important;
    width: auto !important;
  }
}

.menu[aria-hidden="true"] {
  transform: translateY(0);

  &:global(.transitioned) {
    z-index: -1;
  }
}

.menu[aria-hidden="false"] {
  transform: translateY(-100%);
}

.menu_content {
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.header {
  composes: page-container from global;

  color: inherit;
  text-align: center;

  flex: 0 0 auto;

  padding: {
    left: var(--web-module-padding);
    right: var(--web-module-padding);
    bottom: var(--line-height-large);
  }

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.het_hem {
  grid-column: 1 / 4;
  grid-row: 1;
}

.language_switcher {
  color: inherit;

  grid-column: 3;
  grid-row: 1;

  text-align: right;

  @include breakpoints(xs) {
    grid-column: 1;
    text-align: left;
  }

  @include breakpoints(s) {
    margin-right: calc(
      (var(--line-height-large) * 1.5) - var(--web-module-padding)
    );
  }
}

.items {
  composes: page-container from global;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: {
    left: var(--web-module-padding);
    right: var(--web-module-padding);
    bottom: calc(var(--line-height-large) * 2);
  }
}
