.root {
  font-family: bryant-web-compressed, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  font-weight: normal; }
  @media all and (max-width: 767px) {
    .root {
      font-size: 4.68rem;
      line-height: 4.55rem;
      letter-spacing: -0.01em; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .root {
      font-size: 5.76rem;
      line-height: 5.6rem;
      letter-spacing: -0.01em; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .root {
      font-size: 5.76rem;
      line-height: 5.6rem;
      letter-spacing: -0.01em; } }
  @media (min-width: 1200px) and (max-width: 1680px) {
    .root {
      font-size: 6.48rem;
      line-height: 6.3rem;
      letter-spacing: -0.01em; } }
  @media (min-width: 1681px) {
    .root {
      font-size: 7.2rem;
      line-height: 7rem;
      letter-spacing: -0.01em; } }

[data-level='2'] .root,
[data-level='3'] .root {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 0.4' overflow='visible'%3E%3Cline stroke='' stroke-linecap='round' x1='0.4' y1='0.2' x2='99.6' y2='0.2' vector-effect='non-scaling-stroke' stroke-width='4' /%3E%3C/svg%3E");
  background-size: 100% 0.4rem;
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-clip: border-box;
  padding-top: .15em; }
  @media all and (max-width: 767px) {
    [data-level='2'] .root,
    [data-level='3'] .root {
      background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 0.24' overflow='visible'%3E%3Cline stroke='' stroke-linecap='round' x1='0.24' y1='0.12' x2='99.76' y2='0.12' vector-effect='non-scaling-stroke' stroke-width='2.4' /%3E%3C/svg%3E");
      background-size: 100% 0.24rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    [data-level='2'] .root,
    [data-level='3'] .root {
      background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 0.3' overflow='visible'%3E%3Cline stroke='' stroke-linecap='round' x1='0.3' y1='0.15' x2='99.7' y2='0.15' vector-effect='non-scaling-stroke' stroke-width='3' /%3E%3C/svg%3E");
      background-size: 100% 0.3rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    [data-level='2'] .root,
    [data-level='3'] .root {
      background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 0.3' overflow='visible'%3E%3Cline stroke='' stroke-linecap='round' x1='0.3' y1='0.15' x2='99.7' y2='0.15' vector-effect='non-scaling-stroke' stroke-width='3' /%3E%3C/svg%3E");
      background-size: 100% 0.3rem; } }
  @media (min-width: 1200px) and (max-width: 1680px) {
    [data-level='2'] .root,
    [data-level='3'] .root {
      background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 0.3' overflow='visible'%3E%3Cline stroke='' stroke-linecap='round' x1='0.3' y1='0.15' x2='99.7' y2='0.15' vector-effect='non-scaling-stroke' stroke-width='3' /%3E%3C/svg%3E");
      background-size: 100% 0.3rem; } }
