@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
}

.root::before {
  background-image: inherit;

  content: " ";
  display: block;

  width: 100%;

  height: var(--line-height-large);

  position: absolute;
  top: calc(-1 * var(--line-height-large) + 1px);
  left: 0;
  right: 0;
  transform: scaleY(-1);

  pointer-events: none;

  transition: height 0.5s, top 0.5s;

  :global(.chapter_page__is_active) & {
    height: calc(3 * var(--line-height-large));
    top: calc(-3 * var(--line-height-large) + 1px);
  }
}

.content {
  composes: bryant from global;

  padding-left: 0;
  padding-right: 0;

  margin-top: var(--web-module-margin-bottom);

  position: relative;
}

@for $i from 1 through chapter(max-num) {
  .content-#{$i} {
    composes: chapter-#{$i}-gr from global;
  }
}

.content-3-5 {
  composes: chapter-3-5-gr from global;
}

.video {
  background-image: inherit;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;

  will-change: transform;

  z-index: var(--z-index-chapter-header--video);
}

.video::before {
  background-image: inherit;

  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 2;
}

.header {
  composes: bryant font-large page-container from global;

  margin-top: 0;

  padding: {
    left: var(--web-module-padding);
    right: var(--web-module-padding);
  }

  min-height: calc(var(--vh) * 100 - var(--line-height-large) * 3.5);

  background-size: 100% 100%;
  background-origin: content-box;

  z-index: var(--z-index-chapter-header);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@for $i from 1 through chapter(max-num) {
  .header-#{$i} {
    composes: svg-c-black-4-#{$i} from global;
    composes: header;
  }
}

.header-3-5 {
  composes: svg-c-black-4-3-5 from global;
  composes: header;
}

.heading {
  composes: bryant font-large font-normal from global;

  margin: {
    top: 0;
    bottom: 0;
  }

  text-align: center;

  background-origin: content-box;

  position: relative;

  transition: padding-top ease-in-out 1s;

  will-change: padding-top;
}

:global(html[data-state="het-hem"]) .heading {
  background-position-y: 0;
  padding-top: 0;
}

.heading a {
  color: inherit;
  display: block;
  width: 100%;
  text-decoration: none;
}

.heading_hidden {
  visibility: hidden;

  margin-bottom: calc(var(--line-height-large) / 2);
}

.heading_clone {
  position: -webkit-sticky;
  position: sticky;
  top: var(--line-height-large);
  z-index: var(--z-index-sticky-chapter-header);

  margin-bottom: calc(-1 * var(--line-height-large));
}

:global(.user_signed_in) {
  .heading_clone {
    top: 0;
  }
}

.heading_clone_bg {
  background-color: var(--background-color);
}

.active_heading {
  cursor: pointer;
}

.guest, .motto {
  margin: {
    top: 0;
    bottom: calc(var(--line-height-large) / 2);
  };
  position: relative;
}

.date_range {
  composes: hide-day-name hide-current-year from global;

  flex: 1 0 auto;
  position: relative;
}

.date_range div {
  display: inline;
}

.buttons {
  composes: font-default from global;

  padding-bottom: calc(var(--line-height-small) / 2);
  position: relative;

  margin: {
    top: var(--line-height-large);
  }
}

.iframe {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  border: none;

  z-index: 0;

  pointer-events: all;
}
