@mixin button(
  $type: default,
  $size: default,
  $background-color: var(--color-black),
  $text-color: var(--color-gray)
) {
  @include bryant-compressed;
  @include font-size($size);

  display: inline-flex;

  border: 0.2rem solid;
  @include breakpoints(m s xs) {
    border: 0.1rem solid;
  }
  border-radius: 1em;

  padding: 0.05em;
  padding-left: 0.4em;
  padding-right: 0.4em;

  text-decoration: none;
  text-align: center;

  cursor: pointer;
  user-select: none;

  background-color: $background-color;
  border-color: $background-color;
  color: $text-color;

  @if ($type == outline) {
    background: none;
    color: $text-color;
    border-color: $text-color;

    &:hover {
      background-color: $text-color;
      color: $background-color;
    }
  }
}
