[class*="module__content"],
[class*="module__form"] {
  padding: var(--web-module-padding); }

div:not(.modulor_web_module__is_edit_mode) .analytics_module, div:not(.modulor_web_module__is_edit_mode)
.column_module, div:not(.modulor_web_module__is_edit_mode)
.flash_module, div:not(.modulor_web_module__is_edit_mode)
.menu_module, div:not(.modulor_web_module__is_edit_mode)
.modal_module, div:not(.modulor_web_module__is_edit_mode)
.yield_module {
  padding: 0; }

.toolbar_module {
  padding: calc(var(--web-module-padding) / 2); }
