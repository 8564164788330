@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
  position: relative;
  overflow: visible;
}

.width_default {
  --stroke-width: var(--stroke-width-default);
}

.width_large {
  --stroke-width: var(--stroke-width-large);
}

.position_top.width_default,
.position_bottom.width_default {
  height: calc(var(--line-height-small) / 4);
}

.position_top.width_large,
.position_bottom.width_large {
  height: calc(var(--line-height-small) / 4);
}

.svg {
  position: absolute;

  pointer-events: none;
  user-select: none;

  overflow: visible;

  svg {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    overflow: visible;
  }

  line,
  path,
  polyline {
    stroke: var(--border-color, currentColor);
    stroke-width: var(--stroke-width);
  }
}

.position_top .svg {
  bottom: 100%;
  left: 50%;
  transform: translateY(50%) translateX(-50%);

  width: calc(100% - var(--stroke-width));
}

.position_bottom .svg {
  top: 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  width: calc(100% - var(--stroke-width));
}

.position_top.width_default .svg,
.position_bottom.width_default .svg {
  height: 3px;
}

.position_top.width_large .svg,
.position_bottom.width_large .svg {
  height: 4px;
}

.position_left,
.position_right {
  height: 100%;
}

.position_left .svg,
.position_right .svg {
  height: calc(100% - var(--stroke-width));
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.position_left.width_default .svg,
.position_right.width_default .svg {
  width: 3px;
}

.position_left.width_large .svg,
.position_right.width_large .svg {
  width: 4px;
}
