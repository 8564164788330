.root {
  composes: hide-current-year hide-time from global;

  font-size: inherit;
  line-height: inherit;

  width: 100%;
}

.root a {
  display: block;
}

.pre {
  composes: font-default from global;

  display: flex;
  flex-wrap: wrap;
}

.pre>* {
  margin-right: 0.5em;
}

.pre div:first-child {
  display: inline-flex;
}

.pre div:first-child:empty {
  display: none;
}

.pre div:first-child:empty+.date_time_range {
  display: inline-flex;
}

.date_time_range {
  display: none;
}

.related_page {}

.title {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-bottom: 0.1em;
  margin-bottom: -0.1em;
}

.underline {
  composes: link-underline from global;
}
