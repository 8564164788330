@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
[data-text-black] .container {
  --text-color: var(--color-black);
  --border-color: var(--text-color);

  color: var(--text-color);
}

.content {
  margin-bottom: calc(var(--line-height-large) / 2);

  padding: 0;

  position: fixed;

  padding-left: 0;
  padding-right: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-index-sticky-het-hem-header);

  background-color: var(--het-hem-header-background-color, none);
}

.content[data-search] {
  z-index: var(--z-index-search);
}

.content:not([data-search]) .search {
  display: none;
}

.content[data-search] .container {
  display: none;
}

:global(.user_signed_in) {
  .content {
    position: -webkit-sticky;
    position: sticky;
  }
}

:global(.modulor_web_module__edit_mode_wrapper) {
  .content {
    background-color: transparent;
  }
}

.header {}

.container {
  composes: page-container from global;

  padding: {
    left: var(--web-module-padding);
    right: var(--web-module-padding);
  }

  position: relative;

  display: grid;
  grid-template-columns: 2fr auto 1fr 1fr;
  grid-template-areas: "search_button search_button heading couch language_switcher";
  align-items: center;
  column-gap: 2em;

  @include breakpoints(xs s) {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: "search_button heading couch";
  }
}

.button {
  grid-area: search_button;

  margin: 0;
  padding: 0;
  background: none;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: inherit;

  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: var(--font-size-large);
  line-height: inherit;

  cursor: pointer;

  width: 1em;
  height: 1em;
}

.button svg {
  width: auto;
  height: 60%;
}

.heading {
  composes: bryant font-large font-normal from global;

  grid-area: heading;

  margin: {
    top: 0;
    bottom: 0;
  }

  text-align: center;

  white-space: nowrap;
}

.heading a {
  color: inherit;
  text-decoration: none;
}

.couch {
  composes: font-large from global;

  grid-area: couch;

  display: flex;
  justify-content: end;

  @include breakpoints(xs s) {
    padding-right: 1em;
  }
}

.couch a {
  color: inherit;
  text-decoration: none;

  display: block;
  border-radius: 100%;
  background-color: transparent;

  width: .8em;
  height: .8em;

  display: flex;
  align-items: center;
  justify-content: center;
}

.couch a>* {
  width: 80%;
  height: 80%;
}

.language_switcher {
  composes: stroke-width-default from global;

  grid-area: language_switcher;

  justify-self: end;

  text-align: right;

  @include breakpoints(xs s) {
    display: none;
  }
}

.search {}

:global(.modulor_web_module__is_edit_mode) .content {
  position: static;
}

:global(.modulor_web_module__is_edit_mode) .header {
  background-color: transparent;
}
