[data-text-black] .container {
  --text-color: var(--color-black);
  --border-color: var(--text-color);
  color: var(--text-color); }

.content {
  margin-bottom: calc(var(--line-height-large) / 2);
  padding: 0;
  position: fixed;
  padding-left: 0;
  padding-right: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-index-sticky-het-hem-header);
  background-color: var(--het-hem-header-background-color, none); }

.content[data-search] {
  z-index: var(--z-index-search); }

.content:not([data-search]) .search {
  display: none; }

.content[data-search] .container {
  display: none; }

:global(.user_signed_in) .content {
  position: -webkit-sticky;
  position: sticky; }

:global(.modulor_web_module__edit_mode_wrapper) .content {
  background-color: transparent; }

.container {
  composes: page-container from global;
  padding-left: var(--web-module-padding);
  padding-right: var(--web-module-padding);
  position: relative;
  display: grid;
  grid-template-columns: 2fr auto 1fr 1fr;
  grid-template-areas: "search_button search_button heading couch language_switcher";
  align-items: center;
  column-gap: 2em; }
  @media all and (max-width: 767px) {
    .container {
      grid-template-columns: 1fr auto 1fr;
      grid-template-areas: "search_button heading couch"; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .container {
      grid-template-columns: 1fr auto 1fr;
      grid-template-areas: "search_button heading couch"; } }

.button {
  grid-area: search_button;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: var(--font-size-large);
  line-height: inherit;
  cursor: pointer;
  width: 1em;
  height: 1em; }

.button svg {
  width: auto;
  height: 60%; }

.heading {
  composes: bryant font-large font-normal from global;
  grid-area: heading;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap; }

.heading a {
  color: inherit;
  text-decoration: none; }

.couch {
  composes: font-large from global;
  grid-area: couch;
  display: flex;
  justify-content: end; }
  @media all and (max-width: 767px) {
    .couch {
      padding-right: 1em; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .couch {
      padding-right: 1em; } }

.couch a {
  color: inherit;
  text-decoration: none;
  display: block;
  border-radius: 100%;
  background-color: transparent;
  width: .8em;
  height: .8em;
  display: flex;
  align-items: center;
  justify-content: center; }

.couch a > * {
  width: 80%;
  height: 80%; }

.language_switcher {
  composes: stroke-width-default from global;
  grid-area: language_switcher;
  justify-self: end;
  text-align: right; }
  @media all and (max-width: 767px) {
    .language_switcher {
      display: none; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .language_switcher {
      display: none; } }

:global(.modulor_web_module__is_edit_mode) .content {
  position: static; }

:global(.modulor_web_module__is_edit_mode) .header {
  background-color: transparent; }
