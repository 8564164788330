.root {
  font-size: inherit;
  line-height: inherit;

  width: 100%;

  margin-top: var(--line-height-default);
}

.root p {
  margin: 0;
  padding: 0;
}

.root a {
  all: inherit;
  cursor: pointer;
}
