@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
$circle-height: 1.3em;
$circle-position-top: 0;
$padding-y: 0.15em;

.root {
  composes: bryant font-default border-gray from global;

  text-align: center;

  padding-top: $padding-y;
  padding-bottom: $padding-y;

  color: var(--text-color);
  text-decoration: none;

  position: relative;
}

.has_event {
  &::before {
    content: "";

    width: $circle-height;
    height: $circle-height;

    position: absolute;
    left: 50%;
    top: $circle-position-top;

    transform: translateX(-50%);

    border: 0.1rem solid var(--color-gray);
    border-radius: 100%;

    pointer-events: none;
  }
}

// NOTE: we use this class in JS, so it has to have some styling
.pickable {
  text-transform: none;
}

.out_of_month,
.before_opening_week {
  visibility: hidden;
}

// @for $i from 1 through chapter(max-num) {
//   .chapter-#{$i} {
//     composes: chapter-#{$i}-c from global;
//   }
// }
//
// .chapter-3-5 {
//   composes: chapter-3-5-c from global;
// }

.closed {
  composes: text-gray-dark from global;
}

.today {
  position: relative;
  color: var(--background-color) !important;

  &::before {
    content: "";

    height: $circle-height;
    width: $circle-height;

    position: absolute;
    left: 50%;
    top: $circle-position-top;

    transform: translateX(-50%);

    border: 0.1rem solid var(--text-color);
    border-radius: 100%;

    background-color: var(--text-color);

    pointer-events: none;

    z-index: -1;
  }
}

.past {
  opacity: initial;
  // opacity: 0.5;
}

.picked {
  --box-width: 100%;

  &::after {
    content: "";

    position: absolute;
    top: $circle-position-top;
    right: 0;

    height: $circle-height;
    width: var(--box-width);

    border-radius: 999999px;
    border-color: var(--border-color, currentColor);
    border-style: solid;
    border-width: 0;

    pointer-events: none;
  }

  &.last_in_week {
    --box-width: 700%;

    &::after {
      border-width: 1px;
    }

    &[data-mday="6"] {
      --box-width: 600%;
    }

    &[data-mday="5"] {
      --box-width: 500%;
    }

    &[data-mday="4"] {
      --box-width: 400%;
    }

    &[data-mday="3"] {
      --box-width: 300%;
    }

    &[data-mday="2"] {
      --box-width: 200%;
    }

    &[data-mday="1"] {
      --box-width: 100%;
    }
  }

  &.last_in_month {
    --box-width: 700%;

    &::after {
      border-width: 1px;
    }

    &[data-wday="6"] {
      --box-width: 600%;
    }

    &[data-wday="5"] {
      --box-width: 500%;
    }

    &[data-wday="4"] {
      --box-width: 400%;
    }

    &[data-wday="3"] {
      --box-width: 300%;
    }

    &[data-wday="2"] {
      --box-width: 200%;
    }

    &[data-wday="1"] {
      --box-width: 100%;
    }
  }
}
