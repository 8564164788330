.root {
  composes: root from "../modal_header_modules/modal_header_module.module.css";
}

.content {
  composes: content from "../modal_header_modules/modal_header_module.module.css";
}

.pill_buttons {
  composes: pill_buttons from "../modal_header_modules/modal_header_module.module.css";
}

.header {
  composes: header from "../modal_header_modules/modal_header_module.module.css";
}

.referring_module_header {
  composes: header;
}
