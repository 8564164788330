.root {
  composes: root from "../modal_pages/modal_page.module.scss";
  composes: bg-black from global;

  --text-color: var(--color-gray);
  color: var(--text-color);
}

.pill_buttons {
  composes: pill_buttons from "../modal_pages/modal_page.module.scss";
}
