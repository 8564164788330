@mixin link($type, $size: default) {
  text-decoration: none;

  @if $type == default {
    &:hover {
      @include underline-for($size);
    }
  }

  @if $type == underline {
    @include underline-for($size);
  }
}
