@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.map_module__marker {
  @include bryant-compressed;
  @include font-size(default);

  .map_module__marker__icon {
    @include bryant-compressed;
    @include font-size(default);

    @include svg-target-bg();

    display: block;

    position: relative;
    top: 0;
    left: 0;

    @include breakpoints(xl l m) {
      @include svg-target-bg(2.5);
      width: calc(#{4 / $columns * 100 / 2}vw - #{$web-module-padding * 2});
      height: calc(#{4 / $columns * 100 / 2}vw - #{$web-module-padding * 2});
    }

    @include breakpoints(s) {
      @include svg-target-bg(2.5);

      width: calc(#{4 / $columns * 100 / 2}vw - #{$web-module-padding * 2});
      height: calc(#{4 / $columns * 100 / 2}vw - #{$web-module-padding * 2});
    }

    @include breakpoints(xs) {
      @include svg-target-bg(2);

      width: calc(#{4 / $columns * 100 / 2}vw - #{$web-module-padding * 2});
      height: calc(#{4 / $columns * 100 / 2}vw - #{$web-module-padding * 2});
    }

    transform: translateX(-50%) translateY(-50%);
  }

  .map_module__marker__info {
    @include bryant-compressed;
    @include font-size(small);
    border: none;
    width: 14em;
  }

  .map_module__marker__info__title {
    font-weight: normal;
    margin-bottom: 1em;
  }

}
