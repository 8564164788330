@charset "UTF-8";
.root,
.root dt,
.root dd {
  margin: 0;
  padding: 0; }

.pages {
  margin: 0;
  padding: 0;
  list-style: none; }

.page:not(:last-child) {
  margin-bottom: 1em; }

.result,
.result dt,
.result dd {
  margin: 0;
  padding: 0; }

.columns {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--line-height-small); }

.parent,
.date {
  composes: font-small from global; }

.heading,
.date {
  composes: font-small hide-current-year from global; }

.heading {
  display: flex;
  column-gap: var(--line-height-small); }

.title {
  composes: font-large from global; }

.root dt.title {
  margin-bottom: var(--line-height-default); }

.occurrences,
.tags {
  list-style: none;
  margin: 0;
  padding: 0; }

.occurrence,
.occurrence *,
.tag,
.tag * {
  display: inline; }

.occurrence:not(:last-child):after,
.tag:not(:last-child):after {
  content: ", "; }

.highlight {
  composes: bryant font-small from global; }

.highlight > span::before {
  content: "… "; }

.highlight > span:last-child::after {
  content: " …"; }

.highlight em {
  font-style: normal;
  background-color: yellow; }
