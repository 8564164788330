.root {
  composes: root from "../modal_header_modules/modal_header_module.module.css";
}

.content {
  composes: content from "../modal_header_modules/modal_header_module.module.css";
}

.pill_buttons {
  composes: pill_buttons from "../modal_header_modules/modal_header_module.module.css";
}

.header {
  composes: header from "../modal_header_modules/modal_header_module.module.css";
}

.referring_module_header {
  composes: header;
}

.headings {
  padding-right: calc(var(--line-height-large) / 2);
}

.subheading {
  composes: font-default from global;

  padding-right: calc(var(--line-height-large) / 2);

  display: flex;
  flex-direction: row;
  align-items: center;
}

.daily_occurrences {
  composes: font-default from global;

  padding: 0;

  margin-top: calc(var(--line-height-large) - var(--line-height-default));
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;

  display: table;
  width: 100%;

  position: relative;
  z-index: calc(var(--z-index-modal--circle) + 1);
}

.daily_occurrence {
  list-style: none;
  display: table-row;
}

.daily_occurrence > * {
  display: table-cell;
}

.daily_occurrence > *:not(:last-child) {
  padding-right: 0.5em;
}

.tickets_link {
  composes: font-small from global;
}

.date_query {
  composes: font-small from global;

  margin-left: calc(var(--line-height-small) / 2);
}

.ticket_details {
  composes: font-small from global;

  padding-left: calc(var(--line-height-small) / 2);

  text-transform: uppercase;
}

.intro {
  composes: font-default from global;

  display: flex;
}

.related_pages {
  all: unset;

  flex: 0 0 auto;
}

.related_page {
  display: inline-flex;

  list-style: none;

  margin-right: 0.5em;
}

.tags {
  flex: 0 0 auto;
}

.tags * {
  margin-right: 0.5em;
  display: inline-flex;
}

.calendar_page {
  composes: font-default from global;
  display: block;
}

.calendar_page a {
  text-decoration: none;
  color: inherit;
}
