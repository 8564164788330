@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
body[data-path="Het-Hem-Old"] {
  .het_hem_page {
    .modulor_web_module:not(.modulor_web_module__is_edit_mode) {
      --background-color: var(--color-gray);
      --het-hem-header-background-color: var(--color-gray);
      --text-color: var(--color-black);
      color: var(--text-color);
    }
}

.het_hem_page {
    background-color: var(--color-gray);
  }
}

body[data-path^="Het-Hem"] {
  --background-color: var(--color-gray);
  --het-hem-header-background-color: var(--color-gray);
  --text-color: var(--color-black);

  --pill-component--default-inverted--bg-color--overwrite: transparent;
  --pill-component--outlined--bg-color--overwrite: transparent;

  color: var(--text-color);
  background-color: var(--background-color);

  .het_hem_page {
    padding-top: calc(var(--line-height-large) * 2);

    --background-color: var(--color-gray);
    --text-color: var(--color-black);

    // .modulor_web_module:not(.modulor_web_module__is_edit_mode):not(.het_hem_header_module) {
    //   --background-color: var(--color-black);
    //   --text-color: var(--color-white);
    //   color: var(--text-color);
    // }
    //
    // .chapter_module ~ .modulor_web_module:not(.modulor_web_module__is_edit_mode) {
    //   --background-color: var(--color-gray);
    //   --text-color: var(--color-black);
    // }

    .section_module + .chapter_module {
      margin-top: calc(var(--web-module-padding) * -3);
    }

    .modulor_web_module:not(.modulor_web_module__is_edit_mode):not(.chapter_module):not(.background_video_module):not(.background_images_module):not(.het_hem_header_module):not(.menu_module) {
      position: relative;
      z-index: 1;
    }
  }
}

body[data-path^="Search"]  {
  --het-hem-header-background-color: var(--color-white);
}

body[data-path^="Search"] .het_hem_page {
  padding-top: calc(var(--line-height-large) * 2);
}

body[data-path="Het-Hem"] {
  .modulor_web_module_header + .modulor_web_module {
    padding-top: 0;
    margin-top: calc(var(--web-module-padding) * -1);
  }
}
