@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 8, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 10, exclusive_query: "(min-width: 1200px) and (max-width: 1680px)", query: "(min-width: 1200px)"), xl: (columns: 12, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Het-Hem/About, artist_spaces: Het-Hem/Artist-Spaces, behavioural_code: Het-Hem/Behavioural-Code, blog: Blog, cafe: Het-Hem/Cafe, calendar: Het-Hem/Calendar, chapters: Het-Hem/Chapters, colophon: Het-Hem/Colophon, dynamic_range: Het-Hem/Dynamic-Range, installations: Het-Hem/Installations, exhibitions: Het-Hem/Exhibitions, het_hem: Het-Hem, living_room: Het-Hem/Living-Room, permanent_collection: Het-Hem/Permanent-Collection, press: Het-Hem/Press, cookie_policy: Het-Hem/Cookie-Policy, privacy_statement: Het-Hem/Privacy-Statement, restaurant_europa: Het-Hem/Restaurant-Europa, search: Search, tickets: Het-Hem/Tickets, tickets_event: Het-Hem/Event-Tickets, visit: Het-Hem/Visit);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.root {
  composes: bryant from global;
}

.heading {
  margin: 0;

  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

.body {
  margin: 0;
}

.body p {
  margin-top: 0;
}

.body a {
  @include link(underline, default);
}

.body table {
  border-collapse: collapse;
}

.body table:not(:last-child) {
  margin-bottom: 1em;
}

.body table th {
  font-weight: inherit;
  text-align: left;
}

.body table th,
.body table td {
  padding: 0;
  padding-top: 0.15em;
  padding-bottom: 0.15em;
  position: relative;

  &:before {
    content: "";
    display: block;
    @include svg-line-bg(horizontal, color(var(--color-text)), 2.5);
    position: absolute;
    top: 0;
    width: 100%;
    height: 0.5em;
    pointer-events: none;
  }
}

.body table tr:last-child td {
  &:after {
    content: "";
    display: block;
    @include svg-line-bg(horizontal, color(var(--color-text)), 2.5);
    position: absolute;
    width: 100%;
    bottom: -0.45em;
    height: 0.5em;
    pointer-events: none;
  }
}

.body td:not(:last-child) {
  padding-right: var(--web-module-padding);

  @include breakpoints(xs) {
    padding-right: calc(var(--web-module-padding) / 2);
  }
}

.body > *:last-child {
  margin-bottom: 0;
}

.small {
  composes: font-small from global;
}

.small p,
.small .heading {
  margin-bottom: var(--line-height-small);
}

.default {
  composes: font-default from global;
}

.default p,
.default .heading {
  margin-bottom: var(--line-height-default);
}

.large {
  composes: font-large from global;

  @include breakpoints(xs) {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
  }
}

.large p,
.large .heading {
  margin-bottom: var(--line-height-large);
}

.body h3,
.body h4 {
  font-weight: inherit;
  font-size: inherit;
  font-weight: inherit;

  &:not(:first-child) {
    margin-top: 2em;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  position: relative;

  &:before {
    content: "";
    display: block;
    @include svg-line-bg(horizontal, color(var(--color-text)), 2.5);
    position: absolute;
    top: -0.1em;
    width: 100%;
    height: 1em;
    pointer-events: none;
  }
}

.body blockquote {
  margin: 0;
  padding: 0;
  padding-left: calc(var(--web-module-padding) * 2);

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.body ul,
.body ol {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.body small {
  @include font-size(small);
  display: block;
}

.body hr {
  font-size: inherit;
  line-height: inherit;

  @include svg-line-bg(horizontal, color(var(--color-text)), 2.5);

  border: 0;
  display: block;
  height: 0.5em;
}

.line_above {
}
